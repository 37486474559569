import React, {Component} from 'react';
import classes from './WhatsNewPage.module.scss';
import {ViewContext} from '../../context/ViewContext';

class WhatsNewPage extends Component<{}> {

    static contextType = ViewContext;
    context!: React.ContextType<typeof ViewContext>;

    componentDidMount() {
        this.context.actions.setToolbarTitle('Changelog');
    }

    render() {
        return (
            <div className={classes.whatsNewPage}>
                <div className={classes.currentVersion}>Aktuelle Version: 1.6.3</div>

                <h3 className={classes.version}>Änderungen in Version 1.6.3</h3>
                <div className={classes.section}>
                    <li>Auf der "Kasse"-Seite wurden die VG-Nummern für eine bessere Lesbarkeit vergrößert.</li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.6.2</h3>
                <div className={classes.section}>
                    <li>Link zum Quellcode in der Impressum-Seite hinzugefügt.</li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.6.1</h3>
                <div className={classes.section}>
                    <li><span role="img" aria-label="Fix">🐞</span> Bei alphabetischer Sortierung der Einkaufsliste
                        werden Produkte nun unabhängig von der Groß- und Kleinschreibung einsortiert. Zuvor wurden
                        zuerst alle groß geschriebenen Produkte angeführt, anschließend die klein geschriebenen.
                    </li>
                    <li>Einige Animationen beschleunigt.</li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.6.0</h3>
                <div className={classes.section}>
                    <li>Optimierung beim Importieren von geteilten Listen: Beim Hinzufügen von Duplikat-Einträgen,
                        wird jetzt die Menge des bestehenden Eintrages angepasst, statt einen doppelten Eintrag mit
                        demselben Titel zu erstellen. Dies soll das Importieren von Listen von den Rezeptseiten der VG
                        erleichtern.
                    </li>
                    <li>Die Einstellungsmöglichkeit "Duplikate vermeiden" wurde entfernt. Stattdessen kann jetzt im
                        Importieren-Dialog ausgewählt werden, ob Duplikate ignoriert oder mit importiert werden sollen.
                    </li>
                    <li>Kassen-Seite verbessert, wenn nichts im Einkaufswagen liegt.</li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.5.1</h3>
                <div className={classes.section}>
                    <li><span role="img" aria-label="Fix">🐞</span> Der QR-Code-Reader sollte das Bild jetzt auch auf
                        neueren Samsung-Geräten scharf stellen.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.5.0</h3>
                <div className={classes.section}>
                    <li>Lesbarkeit bei mehreren Artikel auf der Kassen-Seite verbessert.</li>
                    <li><span role="img" aria-label="Fix">🐞</span> Die Einträge für das Leergut auf der Kassen-Seite
                        lassen sich jetzt auch durchstreichen.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.4.2</h3>
                <div className={classes.section}>
                    <li>In der Hilfe ergänzt, welche Codes mit dem Barcode-Scanner gescannt werden können.</li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.4.1</h3>
                <div className={classes.section}>
                    <li>Safari: Beim Produktscanner sollte jetzt Feedback angezeigt werden, wenn ein Eintrag gefunden
                        wurde.
                    </li>
                    <li>Listen-Import: Es werden jetzt standardmäßig Einträge mit gleichem Namen beim Import
                        übersprungen (Verhalten kann in den Einstellungen angepasst werden).
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.4.0</h3>
                <div className={classes.section}>
                    <li>Impressum angepasst: Die VG hat einen neuen Vorstand.</li>
                    <li>Die Autovervollständigung für das Eingabefeld zum Hinzufügen von Produkten kann jetzt
                        deaktiviert werden. Die Option kann genutzt werden, falls die Vorschlagsliste die Tastatur
                        überlappt, etwa bei kleinen Bildschirmen.
                    </li>
                    <li>Es erscheint jetzt ein Popup, wenn ein Produkt nach dem Hinzufügen einer VG-Nummer in den
                        Einkaufswagen verschoben wurde.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.3.1</h3>
                <div className={classes.section}>
                    <li>Popup bleibt jetzt etwas länger sichtbar</li>
                    <li>Popup bei nicht gefundenem Eintrag weist nun expliziter auf die Möglichkeit hin, einen neuen
                        Eintrag erstellen zu können.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.3.0</h3>
                <div className={classes.section}>
                    <li>Farbgebung und Schriftart der App an den neuen VG Corporate Design Style angepasst.</li>
                    <li>Fontawesome-Icons ersetzt.</li>
                    <li>Darstellung des Info-Dialoges an kleinere Bildschirme angepasst.</li>
                    <li><span role="img" aria-label="Fix">🐞</span> Der "Liste leeren"-Dialog kann nun auch mehrfach
                        aufgerufen werden, ohne dass man zwischendurch die Seite wechseln muss.
                    </li>
                    <li><span role="img" aria-label="Fix">🐞</span> Zwei Einträge mit gleichem Titel wurden manchmal
                        viermal angezeigt, falls die Liste alphabetisch sortiert war und der Mond ungünstig stand.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.2.0</h3>
                <div className={classes.section}>
                    <li><span role="img" aria-label="Fix">🐞</span>Die Einkaufsliste wird nun auch nach dem Sortieren
                        von Einträgen im App-Modus gespeichert.
                    </li>
                    <li>Die Menge eines Artikels kann nun mit Hilfe des "-"-Buttons auf 0.75, 0.5 und 0.25 gesetzt
                        werden.
                    </li>
                </div>

                <h3 className={classes.version}>Änderungen in Version 1.1.0</h3>
                <div className={classes.section}>
                    <li>Hilfe-Seite mit Tipps und Hilfen bei Problemen hinzugefügt.</li>
                    <li>Leergut-Liste enthält nun VG-Nummern.</li>
                </div>
                <h3 className={classes.version}>Änderungen in Version 1.0.0</h3>
                <div className={classes.section}>
                    <li><span role="img" aria-label="Fix">🐞</span> Dialoge werden nun auch bei gescrollter Liste wieder
                        vollständig angezeigt.
                    </li>
                    <li><span role="img" aria-label="Fix">🐞</span> Dialoge werden nun auch bei großem Bildschirm
                        zentriert dargestellt.
                    </li>
                    <li>Link zur VG-News entfernt, da diese aus Sicherheitsgründen nicht dargestellt werden können.</li>
                    <li>Bibliotheken aktualisiert.</li>
                </div>
                <h3 className={classes.version}>Änderungen in Version 0.9.4</h3>
                <div className={classes.section}>
                    <li>Verbesserungen auf der Kamera-Seite</li>
                    <li>Die Eingabeleiste der Einkaufsliste bleibt nun immer eingeblendet.</li>
                    <li>Bestätigungsdialog nach dem Leeren der Liste entfernt.</li>
                    <li>Einträge auf der Kasse-Seite können jetzt abgehakt werden, um das Ansagen
                        an der Kasse zu erleichtern.
                    </li>
                    <li>Einträge werden jetzt nur bei Tap auf die Checkbox oder per Swipe-Geste
                        (von links nach rechts) abgehakt. Ein Tap auf den Namen öffnet den
                        Bearbeiten-Dialog.
                    </li>
                </div>
                <h3 className={classes.version}>Änderungen in Version 0.9.3</h3>
                <div className={classes.section}>
                    <li>
                        <span role="img" aria-label="Fix">🐞</span>
                        Barcode-Scanner: Die Kamera wird nun nicht mehr nach
                        jedem Scan neu
                        gestartet. Damit sollte die Bedienung mit Firefox einfacher werden, da der
                        Kamerazugriff nun nur noch einmalig beim Öffnen der Kamera-Seite gestattet
                        werden muss.
                    </li>
                    <li>Barcode-Scanner: Neues Overlay, wenn ein Artikel erfolgreich gescannt
                        wurde.
                    </li>
                    <li>Einkaufsliste: Die Liste kann jetzt mit anderen geteilt werden. Aktuell
                        ist nur der Import einer geteilten Liste möglich, kein gemeinsames
                        Bearbeiten.
                    </li>
                    <li><span role="img" aria-label="Fix">🐞</span>
                        Einkaufsliste: Es kann nun auch gescrollt werden, wenn
                        der
                        Bearbeitungsmodus zum Verschieben von Einträgen aktiv ist.
                    </li>
                    <li>Kasse-Seite: Backwaren werden nun getrennt aufgeführt.</li>
                    <li>Es ist nun möglich, Einkaufsliste, Einkaufswagen und Leergutliste einzeln zu
                        löschen.
                    </li>
                </div>
                <h3 className={classes.version}>Änderungen in Version 0.9.2</h3>
                <div className={classes.section}>
                    <li>
                        Changelog hinzugefügt.
                    </li>
                    <li>
                        Der Umgang mit mehreren Kameras wurde verbessert: Die App merkt sich jetzt die zuletzt
                        verwendete Kamera und fragt nicht bei jedem Scan erneut, welche Kamera verwendet werden soll.
                        Die Kamera kann auf der Scanner-Seite gewechselt werden.
                    </li>
                    <li>
                        Die Kamera wird jetzt automatisch nach 5 Minuten Inaktivität beendet.
                    </li>
                    <li>
                        Das Zoomen in der App wird jetzt unterbunden.
                    </li>
                    <li>
                        Das Löschen im Bearbeiten-Modus ist nun leichter: Der Klickbereich für den Button wurde
                        vergrößert.
                    </li>
                    <li>
                        Fix: Das Eingabefeld in der Einkaufsliste wird jetzt auch geleert, wenn man einen Eintrag
                        bearbeitet.
                    </li>
                    <li>
                        Fix: Beim Löschen von zuvor sortierten Einträgen wurden manchmal Einträge noch auf der
                        Zur-Kasse-Seite angezeigt.
                    </li>
                </div>
            </div>
        );
    }
}

export default WhatsNewPage;
